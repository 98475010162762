import { AuthGuard, NoAuthGuard } from 'app/core';
import { Route } from '@angular/router';
import { AdminGuard } from '@core/guards/admin.guard';

export const AppRoutes: Route[] = [

  {
    path: ':organizationName/auth',
    loadChildren: () => import('./auth-module/auth.module').then(m => m.AuthModule),
    canActivate: [NoAuthGuard]
  },
  {
    path: ':organizationName/dashboard',
    loadChildren: () => import('./dashboard-module/dashboard.module').then(m => m.DashboardModule),
    canActivate: [AuthGuard],
  },
  {
    path: ':orgId/csr/:csrId',
    loadChildren: () => import('./service-registration/customer-service-registration/customer-service-registration.module').then(m => m.CustomerServiceRegistrationModule),
  },
  {
    path: 'admin',
    loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule),
    canActivate: [AuthGuard, AdminGuard]
  },
  {
    path: '**',
    redirectTo: '/_/auth',
    pathMatch: 'full'
  }
];

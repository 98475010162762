import { Component, OnInit } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { SwUpdate } from '@angular/service-worker';
import { AnalyticsService } from '@core/services/analytics/analytics.service';
import { ApiService } from '@core/services/api.service';
import { UserPromptsService, LabelService } from 'app/core';
// import { environment } from 'environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  public labels: any = {};
  public initialized = false;

  constructor(
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    private swUpdate: SwUpdate,
    private userPromptsService: UserPromptsService,
    private labelService: LabelService,
    public apiService: ApiService,
    private analytics: AnalyticsService,
  ) { }

  async ngOnInit() {
    // this.ensureOrigin();
    this.initialized = true;

    this.labels = (await this.labelService.getLabels('app-root')).data;
    this.matIconRegistry.registerFontClassAlias('material-symbols-outlined', 'material-icons-outlined');
    this.matIconRegistry.registerFontClassAlias('material-symbols-rounded', 'material-icons-rounded');

    ['basket-unfill', 'basket-fill'].map(name => {
      const url = this.domSanitizer.bypassSecurityTrustResourceUrl(`assets/icons/${name}.svg`);
      this.matIconRegistry.addSvgIcon(name, url);
    });

    if (this.swUpdate.isEnabled) {
      setTimeout(() => {
        this.swUpdate.versionUpdates.subscribe((d) => {
          if (d.type == 'VERSION_READY') {
            this.userPromptsService.showToast(
              this.labels.new_version_available,
              this.labels.refresh,
              () => {
                window.location.reload();
              }
            );
          }
        });
      }, 1000 * 60);
    } else {
      console.log('Sw not enabled');
    }

    this.analytics.init();
  }

  // private ensureOrigin(): void {
  // const currentOrigin = window.location.origin;
  // let correctOrigin: string;

  // if (environment.platform === 'staging') {
  //   correctOrigin = 'https://volunteer4work-dev.firebaseapp.com';
  // } else if (environment.production) {
  //   correctOrigin = 'https://app.v4work.com';
  // } else {
  //   correctOrigin = 'http://localhost:4300';
  // }

  // if (currentOrigin === correctOrigin) {
  //   this.initialized = true;
  // } else {
  //   window.location.href = correctOrigin + window.location.pathname;
  // }
  // } 
}
